import { Component, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams, IRowNode } from 'ag-grid-enterprise';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { isObservable, Observable } from 'rxjs';
import { NzSpaceModule } from 'ng-zorro-antd/space';

@Component({
  selector: 'app-ag-grid-actions-column',
  imports: [
    NzDividerModule,
    NzButtonModule,
    NzIconModule,
    NzToolTipModule,
    NzPopconfirmModule,
    NzSpaceModule
  ],
  template: `
    <nz-space [nzSplit]="spaceSplit">
      <ng-template #spaceSplit>
        <nz-divider nzType="vertical"></nz-divider>
      </ng-template>
      @for (action of getActions(); track action) {
        <button
          nz-button
          nzType="link"
          nzSize="small"
          [nz-tooltip]="action.tooltip"
          [nz-popconfirm]="action.confirm"
          (nzOnConfirm)="onAction(action)"
          [nzLoading]="action.loading"
          (click)="action.confirm || onAction(action)"
        ><nz-icon [nzType]="action.icon"/></button>
      }
    </nz-space>
  `,
})
export class AgGridActionColumn implements ICellRendererAngularComp, OnDestroy {

  data!: any;
  actions: Action<any>[] = [];
  node!: IRowNode;


  agInit(params: ICellRendererParams & IActionColumnParams): void {
    this.data = params.data!;
    this.actions = params.actions.map(_ => Object.assign({}, _));
    this.node = params.node;
  }

  onAction(action: Action<any>) {
    const result = action.onAction(this.data, this.node);
    if (result && isObservable(result)) {
      action.loading = true;
      let s$ = result.subscribe(() => {
        action.loading = false;
        s$.unsubscribe();
      });
    }
  }

  getActions(): Action<any>[] {
    return this.actions.filter(action => action.isVisible ? action.isVisible(this.data) : true);
  }

  refresh(params: ICellRendererParams & IActionColumnParams): boolean {
    return false;
  }

  ngOnDestroy() {
  }
}

export interface Action<T> {
  icon: string;
  tooltip: string | TemplateRef<void>;
  tooltipContext?: any;
  tooltipTrigger?: 'click' | 'focus' | 'hover';
  confirm?: string | TemplateRef<void>|null;
  onAction: (data: T, row: IRowNode<T>) => void|Observable<void>;
  isVisible?: (data: T) => boolean;
  loading?: boolean;
}

export interface IActionColumnParams<T = any> {
  actions: Action<T>[];
  data?: T;
}
