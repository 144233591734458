import { ColDef } from "ag-grid-community";
import {
    Action,
    AgGridActionColumn,
    IActionColumnParams
} from "../components/ag-agrid-action-column/ag-grid-actions-column.component";

export class AgGridUtils {
    public static readonly theme = 'ag-theme-alpine';

    static readonly defaultColDef: ColDef = {
        flex: 1,
        resizable: true,
        sortable: false,
        minWidth: 20,
        autoHeaderHeight: true,
        wrapHeaderText: true,
        wrapText: true,
        suppressHeaderMenuButton: true,
        mainMenuItems: [
            'autoSizeAll',
            'resetColumns',
        ]
    };

    static readonly pageSizes = [20, 50, 100];

    static createActionsColumnDef<T>(actions: Action<T>[]): ColDef<T> {
        return {
            headerName: '',
            maxWidth: 100,
            minWidth: 50,
            sortable: false,
            pinned: 'right',
            lockPinned: true,
            lockPosition: 'right',
            lockVisible: true,
            suppressHeaderMenuButton: false,
            headerClass: 'ag-action-column',
            cellClass: 'text-center',
            cellRenderer: AgGridActionColumn,
            cellRendererParams: {actions: actions} as IActionColumnParams<T>,
            getQuickFilterText: params => '',
        }
    }

}